<template>
  <div class="layout-horizontal">
    <div class="layout-flexible layout-vertical">
      <div class="title layout-horizontal layout-middle padding layout-inflexible">
        <back-button class="font-size-small" @click="$router.back()"></back-button>
        <span class="font-weight-bold">问卷管理</span>
      </div>
      <div class="layout-flexible" style="overflow: hidden">
        <ys-infinite-scroll-view style="height: 100%" @loadmore="loadMore">
          <div class="padding-horizontal">
            <div class="send-questionnaire" @click="handleSendQuestionnaireClick">
              <span>
                <i class="fas fa-plus-circle"></i>
                发送问卷
              </span>
            </div>
            <div class="list-item questionnaire-record layout-horizontal layout-middle"
                 v-for="item of questionnaireRecordList" :key="item.id"
                 @click="handleQuestionnaireRecordClick(item)">
              <div class="questionnaire-record-icon layout-inflexible"><i class="fas fa-database"></i></div>
              <div class="margin-left layout-flexible" style="overflow: hidden;">
                <div class="questionnaire-record-title font-size-medium font-nowrap"
                     :class="{'is-active': questionnaireRecord && questionnaireRecord.id === item.id}"
                >{{ item.title }}
                </div>
                <div class="font-color-placeholder font-size-small font-nowrap layout-horizontal layout-middle" style="margin-top: 2px;">
                  <div class="mark-finished" v-if="item.finished">已完成</div>
                  <div class="mark-unfinished" v-else>未完成</div>
                  <div v-if="item.finished" class="margin-left-small">
                    完成于：{{ TimeUtils.formatNormal(item.finishedAt) }}
                  </div>
                  <div v-else class="margin-left-small">发送于：{{ TimeUtils.formatNormal(item.time) }}</div>
                </div>
              </div>
              <div class="layout-inflexible">
                <ys-button round type="secondary" lighting size="small" class="btn-delete" @click.stop="handleQuestionnaireRecordDeleteClick(item)">
                  <span class="font-color-danger"><i class="far fa-trash-alt"></i></span>
                </ys-button>
              </div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <div class="layout-inflexible" style="width: 418px; background-color: #c2e7b0">
      <questionnaire-record-panel
          :questionnaire-record-id="questionnaireRecord ? questionnaireRecord.id : null"
          :organization-id="organizationId"
          :user-id="userId"
          style="height: 100%;"
          @update="handleQuestionnaireRecordUpdate">
      </questionnaire-record-panel>
    </div>
    <delete-dialog :visible.sync="deleteRemindDialogVisible" message="您确定要删除该问卷记录吗？" @confirm="handleQuestionnaireRecordDeleteConfirm"></delete-dialog>
    <questionnaire-picker-dialog :visible.sync="questionnairePickerDialogVisible" :organization-id="organizationId" :max-selected-count="5" @pick="handleQuestionnairePicked"></questionnaire-picker-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsButton from "@/components/wedigets/YsButton";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import QuestionnairePickerDialog from "@/components/dialog/QuestionnairePickerDialog";
import QuestionnaireRecordPanel from "@/pages/questionnaire_record/panel/QuestionnaireRecordPanel";

function doLoadQuestionnaireRecordList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/questionnaire/record/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if(this.requireRefresh) this.questionnaireRecordList = [];
    this.requireRefresh = false;
    this.$appendAfter(this.questionnaireRecordList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    if (pageNum === 1 && !this.questionnaireRecord && this.$route.query.id) {
      this.busyLoadingCode = 0;
      doLoadQuestionnaireRecord.bind(this)(this.$route.query.id);
    }

  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadQuestionnaireRecord(id) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.$reqGet({
    path: '/questionnaire/record/get',
    data: {
      id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (res.data.user.id !== this.userId) return;
    this.$appendAfter(this.questionnaireRecordList, [res.data]);
    if (this.$route.query.id === res.data.id) {
      this.questionnaireRecord = res.data;
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doDeleteQuestionnaireRecord(id) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/questionnaire/record/remove',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    let deleteIndex = -1;
    for(let n = this.questionnaireRecordList.length - 1; n >= 0; n--) {
      if(this.questionnaireRecordList[n].id === id) {
        deleteIndex = n;
        this.questionnaireRecordList.splice(n, 1);
        break;
      }
    }
    if(this.questionnaireRecord.id === id) {
      if(deleteIndex >= 0 && deleteIndex < this.questionnaireRecordList.length) {
        this.questionnaireRecord = this.questionnaireRecordList[deleteIndex];
      } else if(deleteIndex - 1 >= 0 && deleteIndex - 1 < this.questionnaireRecordList.length) {
        this.questionnaireRecord = this.questionnaireRecordList[deleteIndex - 1];
      } else {
        if(this.questionnaireRecordList.length > 0) {
          this.questionnaireRecord = this.questionnaireRecordList[0];
        } else {
          this.questionnaireRecord = null;
        }
      }
    }
    window.eventBus.$emit('update:questionnaire_record');
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doSendQuestionnaire() {
  if(this.questionnairePreparedForSending.length == 0) return;
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let questionnaireId = this.questionnairePreparedForSending[0];
  this.questionnairePreparedForSending.splice(0, 1);
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/questionnaire/record/send',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      questionnaireId,
    }
  }).then((res)=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    if(this.questionnairePreparedForSending.length > 0) {
      doSendQuestionnaire.bind(this)();
    } else {
      this.requireRefresh = true;
      this.$route.query.id = res.data.id;
      this.loadQuestionnaireRecordList();
      window.eventBus.$emit('update:questionnaire_record');
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('发送失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "QuestionnaireRecordListPanel",
  mixins: [httpapi],
  components: {
    QuestionnaireRecordPanel,
    QuestionnairePickerDialog, DeleteDialog, YsButton, YsInfiniteScrollView, BackButton},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      TimeUtils,

      loadingCode: 1,
      busyLoadingCode: 0,

      questionnaireRecord: null,
      questionnaireRecordList: [],
      pageNum: 1,
      totalPage: 0,

      requireRefresh: false,

      questionnairePickerDialogVisible: false,

      deleteRemindDialogVisible: false,
      deletingQuestionnaireRecord: null,

      questionnairePreparedForSending: [],
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      };
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.reload();
      },
      immediate: true,
    }
  },
  methods: {
    loadQuestionnaireRecordList(pageNum = 1) {
      doLoadQuestionnaireRecordList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadQuestionnaireRecordList(this.pageNum + 1);
      }
    },
    handleQuestionnaireRecordClick(questionnaireRecord) {
      this.questionnaireRecord = questionnaireRecord;
    },
    handleQuestionnaireRecordDeleteClick(questionnaireRecord) {
      this.deletingQuestionnaireRecord = questionnaireRecord;
      this.deleteRemindDialogVisible = true;
    },
    handleQuestionnaireRecordDeleteConfirm() {
      if(this.deletingQuestionnaireRecord) {
        doDeleteQuestionnaireRecord.bind(this)(this.deletingQuestionnaireRecord.id);
      }
    },
    handleQuestionnairePicked(questionnaireList) {
      this.questionnairePreparedForSending = this.questionnairePreparedForSending.concat(questionnaireList.map(x=>x.id));
      doSendQuestionnaire.bind(this)();
    },
    handleSendQuestionnaireClick() {
      this.questionnairePickerDialogVisible = true;
    },
    handleQuestionnaireRecordUpdate(id) {
      doLoadQuestionnaireRecord.bind(this)(id);
    },
    reload() {
      this.loadingCode++;
      this.questionnaireRecordList = [];
      this.pageNum = 1;
      this.totalPages = 0;
      this.questionnaireRecord = null;
      if (this.userId && this.organizationId) {
        this.loadQuestionnaireRecordList();
      }
    }
  },
  activated() {
    this.reload();
  }
}
</script>

<style scoped>

.list-item {
  width: auto;
  min-width: auto;
  max-width: none;
}

.questionnaire-record-title.is-active {
  font-weight: bold;
  color: #1f1f1f;
}

.questionnaire-record .btn-delete {
  visibility: hidden;
}

.questionnaire-record:hover .btn-delete {
  visibility: visible;
}

.mark-finished {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #66a0ce;
  color: white;
  font-size: 8px;
  line-height: 12px;
}

.mark-unfinished {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #ff5500;
  color: white;
  font-size: 8px;
  line-height: 12px;
}

.send-questionnaire {
  height: 100px;
  border-radius: 8px;
  border: 1px solid #dadada;
  text-align: center;
  font-size: 18px;
  line-height: 100px;
  width: 75%;
  cursor: pointer;
  user-select: none;
  max-width: 200px;
}

.send-questionnaire:hover {
  box-shadow: 0 0 4px #bec0c0;
}

</style>